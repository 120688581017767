import React from 'react';

import PageIcon from '@atlaskit/icon/core/page';

import { Ati } from '@atlassian/ari';
import type { UniversalCreateItems } from '@atlassian/universal-create';
import UniversalCreateButton from '@atlassian/universal-create';

export function UniversalCreate() {
	const items: UniversalCreateItems = [
		{
			ati: Ati.parse('ati:cloud:confluence:page'),
			children: 'Page',
			elemBefore: <PageIcon label="" />,
			onClick: () => {},
		},
	];
	return <UniversalCreateButton items={items} />;
}
