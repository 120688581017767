/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { useCallback } from 'react';

import { jsx } from '@compiled/react';
import { ErrorBoundary } from 'react-error-boundary';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { SkeletonItem } from '@atlaskit/menu';
import { Box, xcss } from '@atlaskit/primitives';
import { MenuButtonItem, MenuLinkItem } from '@atlassian/navigation-system';

import { MenuItem, WrapperRenderProps } from '../../../types';
import { useMenuControl } from '../../../utils';

const itemWrapStyles = xcss({
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
	width: '246px',
});

function Item({ item }: { item: MenuItem }) {
	const menuControl = useMenuControl();

	const {
		onClick,
		isLoading,
		loadCreateContent: createContent,
		onClickCloseMenu = true,
		children,
		Wrapper,
		...itemProps
	} = item;

	const buttonClick = useCallback(
		(
			event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
			analyticsEvent: UIAnalyticsEvent,
		) => {
			if (createContent) {
				menuControl.load(createContent);
			} else if (onClickCloseMenu) {
				menuControl.close();
			}

			onClick?.(event, analyticsEvent);
		},
		[menuControl, onClick, onClickCloseMenu, createContent],
	);

	const renderButton = useCallback(
		(props?: WrapperRenderProps) => {
			const { triggerProps, visualContentRef } = props || {};
			return (
				<Box xcss={itemWrapStyles}>
					{'href' in itemProps ? (
						<MenuLinkItem
							{...triggerProps}
							{...(visualContentRef ? { visualContentRef } : {})}
							{...itemProps}
							onClick={buttonClick}
							children={children}
						/>
					) : (
						<MenuButtonItem
							{...triggerProps}
							{...(visualContentRef ? { visualContentRef } : {})}
							{...itemProps}
							onClick={buttonClick}
							children={children}
						/>
					)}
				</Box>
			);
		},
		[buttonClick, children, itemProps],
	);

	if (isLoading) {
		return <SkeletonItem testId={`loading-${itemProps?.testId}`} isShimmering />;
	}

	if (Wrapper) {
		return <Wrapper>{(props?: WrapperRenderProps) => renderButton(props)}</Wrapper>;
	}

	return renderButton();
}

export default function MenuContentItem({ item }: { item: MenuItem }) {
	return (
		<ErrorBoundary fallback={null}>
			<Item item={item} />
		</ErrorBoundary>
	);
}
