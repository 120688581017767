import { LoadableLazy } from '@confluence/loadable';

export const DatabaseGAOnboardingModalManager = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-DatabaseGAOnboardingModalManagerComponent" */ './ga/DatabaseGAOnboardingModalManager'
			)
		).DatabaseGAOnboardingModalManagerComponent,
});
