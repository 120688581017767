import { LoadableLazy } from '@confluence/loadable';

export const DatabasesBetaModalManagerLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-DatabasesBetaModalManager" */ '../src/databases/DatabasesBetaModalManager'
			)
		).DatabasesBetaModalManager,
});
