import { LoadableLazy } from '@confluence/loadable';

export const LoomBetaAdminOptInOnboardingModalManagerLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-LoomBetaAdminOptInOnboardingModalManager" */ '../src/loom/LoomBetaAdminOptInOnboardingModalManager'
			)
		).LoomBetaAdminOptInOnboardingModalManager,
});
