import { parse, format } from 'url';

import idx from 'idx';
import gql from 'graphql-tag';
import type { ApolloError } from 'apollo-client';

import { CONTEXT_PATH } from '@confluence/named-routes';
import { getApolloCache } from '@confluence/graphql';
import { fg } from '@confluence/feature-gating';

import type { ParentPageQuery as ParentPageQueryType } from './queries/__types__/ParentPageQuery';

export type BlankPageCreationOverrides = {
	parentPageId?: string;
	spaceKey?: string;
};

const isCreateBlankPageInFabricEnabled = () => {
	return (
		fg('confluence_frontend_fabric_editor_blank_page') ||
		fg('confluence_frontend_fabric_editor_all_pages')
	);
};

export const isCreateBlankFabricPageEnabled = () => {
	return isCreateBlankPageInFabricEnabled();
};

export const shouldUseParentPageId = (
	parentPageResponse: ParentPageQueryType,
	creationSpaceKey: string,
): boolean => {
	// parent page is in the selected space
	const spaceKey = idx(parentPageResponse, (_) => _.content.nodes[0].space.key);
	if (spaceKey !== creationSpaceKey) {
		return false;
	}

	// ancestors must be of the same content Type
	const contentType = idx(parentPageResponse, (_) => _.content.nodes[0].type);
	if (contentType !== 'page') {
		return false;
	}

	// parent page must not be archived
	const status = idx(parentPageResponse, (_) => _.content.nodes[0].status);
	if (status === 'archived') {
		return false;
	}

	// must have create access under the parent page
	const operations = idx(parentPageResponse, (_) => _.content.nodes[0].operations);
	const hasCreateOperation =
		operations && operations.find((op) => op.targetType === 'page' && op.operation === 'create');

	return !!hasCreateOperation;
};

export const handleOverride = <T>(
	overrideValue: T,
	defaultValue: T,
): { value: T; usedOverride: boolean } => {
	return {
		value: overrideValue || defaultValue,
		usedOverride: !!overrideValue,
	};
};

// TEMPORARY, DO NOT WRITE ARBITRARY KEYS
// TO APOLLO CACHE
const writeEditorMode_DO_NOT_USE = () => {
	try {
		getApolloCache().writeQuery({
			// eslint-disable-next-line graphql-relay-compat/consistent-graphql-operation-naming -- Read https://go/connie-relay-migration-fyi
			query: gql`
				query WriteFabricEditor {
					fabricEditor @client {
						editorMode
					}
				}
			`,
			data: {
				fabricEditor: {
					__typename: 'FabricEditor',
					editorMode: 'create-page',
				},
			},
		});
	} catch (_e) {}
};

export const appendQueryParams = (urlToChange: string, params: { [p: string]: any }) => {
	writeEditorMode_DO_NOT_USE();

	const urlComponents = parse(urlToChange, true);
	return format({
		...urlComponents,
		// remove `search`, otherwise
		// `query` will be ignored
		search: undefined,
		query: {
			...urlComponents.query,
			...params,
		},
	});
};

export const processDraftUrl = (url: string | undefined | null): string => {
	if (!url) {
		throw new Error('No url for new content');
	}
	if (url.startsWith(CONTEXT_PATH)) {
		return url;
	}
	return `${CONTEXT_PATH}${url}`;
};

export const graphqlErrorHasMessage = (
	error: ApolloError | Error | null | undefined,
	messageMatches: string[],
): boolean => {
	if (error && 'graphQLErrors' in error) {
		return error.graphQLErrors?.some(({ message }) =>
			messageMatches.some((messageMatch) => message.includes(messageMatch)),
		);
	}
	return false;
};
