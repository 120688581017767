import { createContext, useContext } from 'react';

import { Ati } from '@atlassian/ari';

import { DIVIDER } from './constants';
import {
	MenuControlContext as ContextProps,
	MenuItem,
	MixedMenuItems,
	SimpleMenuItems,
	UniversalCreateItems,
} from './types';

export function isSimpleMenuItems(items: UniversalCreateItems): items is SimpleMenuItems {
	return Array.isArray(items) && items.every((item) => !Array.isArray(item) && item !== DIVIDER);
}

export function normalizeItems(items: UniversalCreateItems): MixedMenuItems {
	if (isSimpleMenuItems(items)) {
		return [items];
	}
	return items;
}

export function findSelectedItem(items: MixedMenuItems, selectedItem: Ati): MenuItem | null {
	for (const item of items) {
		if (Array.isArray(item)) {
			for (const menuItem of item) {
				if (menuItem.ati.toString() === selectedItem.toString()) {
					return menuItem;
				}
			}
		}
	}
	return null;
}

const defaultFormControls: ContextProps = {
	load: function (): void {},
	reset: function (): void {},
	close: function (): void {},
	items: [],
};

export const MenuControlContext = createContext<ContextProps>(defaultFormControls);

export function useMenuControl() {
	return useContext(MenuControlContext);
}
